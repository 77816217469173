const LocationData = [
    {
        City: "Anaheim",
        State: "CA",
        Id: "anaheimgaragedoor.org",
        Url: "https://www.anaheimgaragedoor.org",
        Phone: "951-724-3840"
    },
    {
        City: "Calabasas",
        State: "CA",
        Id: "calabasasgaragedoor.org",
        Url: "https://www.calabasasgaragedoor.org",
        Phone: "747-338-6494"
    },
    {
        City: "Corona",
        State: "CA",
        Id: "coronagaragedoor.org",
        Url: "https://www.coronagaragedoor.org",
        Phone: "951-528-4202"
    },
    {
        City: "Fontana",
        State: "CA",
        Id: "fontanagaragedoor.org",
        Url: "https://www.fontanagaragedoor.org",
        Phone: "951-389-7642"
    },
    {
        City: "Glendale",
        State: "CA",
        Id: "glendalegaragedoor.org",
        Url: "https://www.glendalegaragedoor.org",
        Phone: "747-832-2583"
    },
    {
        City: "Long Beach",
        State: "CA",
        Id: "longbeachgaragedoor.org",
        Url: "https://www.longbeachgaragedoor.org",
        Phone: "562-553-8276"
    },
    {
        City: "Moreno Valley",
        State: "CA",
        Id: "morenovalleygaragedoor.org",
        Url: "https://www.morenovalleygaragedoor.org",
        Phone: "951-357-0879"
    },
    {
        City: "Oxnard",
        State: "CA",
        Id: "oxnardgaragedoor.org",
        Url: "https://www.oxnardgaragedoor.org",
        Phone: "805-401-0312"
    },
    {
        City: "Pasadena",
        State: "CA",
        Id: "pasadenagaragedoor.org",
        Url: "https://www.pasadenagaragedoor.org",
        Phone: "213-715-4797"
    },
    {
        City: "Pomona",
        State: "CA",
        Id: "pomonagaragedoor.org",
        Url: "https://www.pomonagaragedoor.org",
        Phone: "951-396-3172"
    },
    {
        City: "Rancho Cucamonga",
        State: "CA",
        Id: "ranchocucamongagaragedoor.org",
        Url: "https://www.ranchocucamongagaragedoor.org",
        Phone: "951-406-6196"
    },
    {
        City: "Redlands",
        State: "CA",
        Id: "redlandsgaragedoor.org",
        Url: "https://www.redlandsgaragedoor.org",
        Phone: "951-418-2606"
    },
    {
        City: "Riverside",
        State: "CA",
        Id: "riversidegaragedoor.org",
        Url: "https://www.riversidegaragedoor.org",
        Phone: "951-518-8420"
    },
    {
        City: "Santa Clarita",
        State: "CA",
        Id: "santaclaritagaragedoor.org",
        Url: "https://www.santaclaritagaragedoor.org",
        Phone: "661-402-9107"
    },
    {
        City: "San Bernardino",
        State: "CA",
        Id: "sanbernardinogaragedoor.org",
        Url: "https://www.sanbernardinogaragedoor.org",
        Phone: "760-314-4218"
    },
    {
        City: "Santa Ana",
        State: "CA",
        Id: "santaanagaragedoor.org",
        Url: "https://www.santaanagaragedoor.org",
        Phone: "949-326-9508"
    },
    {
        City: "Temecula",
        State: "CA",
        Id: "temeculagaragedoor.org",
        Url: "https://www.temeculagaragedoor.org",
        Phone: "951-528-1229"
    },
    {
        City: "Thousand Oaks",
        State: "CA",
        Id: "thousandoaksgaragedoor.org",
        Url: "https://www.thousandoaksgaragedoor.org",
        Phone: "805-586-9441"
    },
    {
        City: "Torrance",
        State: "CA",
        Id: "torrancegaragedoor.org",
        Url: "https://www.torrancegaragedoor.org",
        Phone: "424-319-1469"
    },
    {
        City: "Ventura",
        State: "CA",
        Id: "venturagaragedoor.org",
        Url: "https://www.venturagaragedoor.org",
        Phone: "805-387-9106"
    },
    {
        City: "Irvine",
        State: "CA",
        Id: "venturagaragedoor.org",
        Url: "https://www.irvinegaragedoor.org",
        Phone: "805-790-7907"
    },
    {
        City: "Huntington Beach",
        State: "CA",
        Id: "venturagaragedoor.org",
        Url: "https://www.huntingtonbeachgaragedoor.org",
        Phone: "714-844-8683"
    },
    {
        City: "Ontario",
        State: "CA",
        Id: "venturagaragedoor.org",
        Url: "https://www.ontariogaragedoor.org",
        Phone: "714-475-7584"
    },
    {
        City: "Santa Clarita",
        State: "CA",
        Id: "venturagaragedoor.org",
        Url: "https://www.santaclaritagaragedoor.org",
        Phone: "661-402-9107"
    },
    {
        City: "Simi Valley",
        State: "CA",
        Id: "venturagaragedoor.org",
        Url: "https://www.simivalleygaragedoor.org",
        Phone: "805-790-7907"
    },
    {
        City: "Santa Monica",
        State: "CA",
        Id: "venturagaragedoor.org",
        Url: "https://www.santamonicagaragedoor.org",
        Phone: "310-929-6134"
    },
    {
        City: "Chino",
        State: "CA",
        Id: "venturagaragedoor.org",
        Url: "https://www.chinogaragedoor.org",
        Phone: "714-908-7479"
    },
    {
        City: "Laguna Niguel",
        State: "CA",
        Id: "venturagaragedoor.org",
        Url: "https://www.lagunaniguelgaragedoor.org",
        Phone: "949-776-5061"
    },
    {
        City: "San Clemente",
        State: "CA",
        Id: "venturagaragedoor.org",
        Url: "https://www.sanclementegaragedoor.org",
        Phone: "949-518-0752"
    },
]
export default LocationData;
